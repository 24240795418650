import React, { Component } from "react";
import Logo from './Logo/Logo';

import classes from "../../containers/App.css";
import NavBar from '../NavigationBar/NavBar/NavBar';

const layout = (props) => (
    <>
        <Logo />
        <main className={classes.Content} >
            {props.children}
        </main>
        <NavBar />
    </>
);

export default layout
