import React, { useRef, useEffect, useState } from "react";
import "../../containers/App.css";
import PhotoCarousel from '../PhotoCarousel/PhotoCarousel';

function Banner() {
  return (
    <>
      <div className="appwrapper">
         <PhotoCarousel />
      </div>
    </>
      
  );
}

export default Banner;