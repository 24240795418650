import img from '../../london/images/LoveLondon-130320_001.jpg'
import img2 from '../../london/images/LoveLondon-130320_002.jpg';
import img3 from '../../london/images/LoveLondon-130320_003.jpg';
import img4 from '../../london/images/LoveLondon-130320_004.jpg';
import img5 from '../../london/images/LoveLondon-130320_005.jpg';
import img6 from '../../london/images/LoveLondon-130320_006.jpg';
import img7 from '../../london/images/LoveLondon-130320_007.jpg';
import img8 from '../../london/images/LoveLondon-130320_008.jpg';
import img9 from '../../london/images/LoveLondon-130320_009.jpg';
import img10 from '../../london/images/LoveLondon-130320_010.jpg';
import img11 from '../../london/images/LoveLondon-130320_011.jpg';
import img12 from '../../london/images/LoveLondon-130320_012.jpg';
import img13 from '../../london/images/LoveLondon-130320_013.jpg';
import img14 from '../../london/images/LoveLondon-130320_014.jpg';
import img15 from '../../london/images/LoveLondon-130320_015.jpg';
import img16 from '../../london/images/LoveLondon-130320_016.jpg';
import img17 from '../../london/images/LoveLondon-130320_017.jpg';
import img18 from '../../london/images/LoveLondon-130320_018.jpg';
import img19 from '../../london/images/LoveLondon-130320_019.jpg';
import img20 from '../../london/images/LoveLondon-130320_020.jpg';
import img21 from '../../london/images/LoveLondon-130320_021.jpg';
import img22 from '../../london/images/LoveLondon-130320_022.jpg';
import img23 from '../../london/images/LoveLondon-130320_023.jpg';
import img24 from '../../london/images/LoveLondon-130320_024.jpg';
import img25 from '../../london/images/LoveLondon-130320_025.jpg';
import img26 from '../../london/images/LoveLondon-130320_026.jpg';
import img27 from '../../london/images/LoveLondon-130320_027.jpg';
import img28 from '../../london/images/LoveLondon-130320_028.jpg';
import img29 from '../../london/images/LoveLondon-130320_029.jpg';
import img30 from '../../london/images/LoveLondon-130320_030.jpg';
import img31 from '../../london/images/LoveLondon-130320_031.jpg';
import img32 from '../../london/images/LoveLondon-130320_032.jpg';
import img33 from '../../london/images/LoveLondon-130320_033.jpg';
import img34 from '../../london/images/LoveLondon-130320_034.jpg';
import img35 from '../../london/images/LoveLondon-130320_035.jpg';
import img36 from '../../london/images/LoveLondon-130320_036.jpg';
import img37 from '../../london/images/LoveLondon-130320_037.jpg';
import img38 from '../../london/images/LoveLondon-130320_038.jpg';
import img39 from '../../london/images/LoveLondon-130320_039.jpg';
import img40 from '../../london/images/LoveLondon-130320_040.jpg';
import img41 from '../../london/images/LoveLondon-130320_041.jpg';

const photoData = [
  {
    image: img,
    name: "Victoria"
  },
  {
    image: img2,
    name: "victoria"
  },
  {
    image: img3,
    name: "Victoria"
  },
  {
    image: img4,
    name: "Victoria"
  },
  {
    image: img5,
    name: "Victoria"
  },
  {
    image: img6,
    name: "pub"
  },
  {
    image: img7,
    name: "pub"
  },
  {
    image: img8,
    name: "pub"
  },
  {
    image: img9,
    name: "pub"
  },
  {
    image: img10,
    name: "pub"
  },
  {
    image: img11,
    name: "pub"
  },
  {
    image: img12,
    name: "pub"
  },
  {
    image: img13,
    name: "pub"
  },
  {
    image: img14,
    name: "pub"
  },
  {
    image: img15,
    name: "pub"
  },
  {
    image: img16,
    name: "pub"
  },
  {
    image: img17,
    name: "pub"
  },
  {
    image: img18,
    name: "pub"
  },
  {
    image: img19,
    name: "pub"
  },
  {
    image: img20,
    name: "pub"
  },
  {
    image: img21,
    name: "pub"
  },
  {
    image: img22,
    name: "pub"
  },
  {
    image: img23,
    name: "pub"
  },
  {
    image: img24,
    name: "pub"
  },
  {
    image: img25,
    name: "pub"
  },
  {
    image: img26,
    name: "pub"
  },
  {
    image: img27,
    name: "pub"
  },
  {
    image: img28,
    name: "pub"
  },
  {
    image: img29,
    name: "pub"
  },
  {
    image: img30,
    name: "pub"
  },
  {
    image: img31,
    name: "pub"
  },
  {
    image: img32,
    name: "pub"
  },
  {
    image: img33,
    name: "pub"
  },
  {
    image: img34,
    name: "pub"
  },
  {
    image: img35,
    name: "pub"
  },
  {
    image: img36,
    name: "pub"
  },
  {
    image: img37,
    name: "pub"
  },
  {
    image: img38,
    name: "pub"
  },
  {
    image: img39,
    name: "pub"
  },
  {
    image: img40,
    name: "pub"
  },
  {
    image: img41,
    name: "pub"
  }
];
export default photoData;
