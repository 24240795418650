import React, { Component } from 'react';
import '../../containers/App.css';
import NavigationItem from './NavigationItem';
import { Icon } from 'react-icons-kit';
import { info } from 'react-icons-kit/fa/info';
import { instagram } from 'react-icons-kit/fa/instagram';
import Info from '../NavigationBar/NavBar/Info/Info';
import {ic_shopping_cart} from 'react-icons-kit/md/ic_shopping_cart'


class NavigationItems extends Component {
    state = { isInfoOpen: false }

    infoToggle = () => {
        // console.log("was clicked");
        this.setState({
            isInfoOpen: !this.state.isInfoOpen
        })
        // console.log(this.state.isInfoOpen);
    }

    render() {
        return (
            <>
                <ul className="NavigationItems">
                    <NavigationItem className="NavigationItem"><div onClick={this.infoToggle}><Icon size={'35px'} icon={info} /></div></NavigationItem>
                    <NavigationItem className="NavigationItem"><a target="_blank" href="https://www.rubikspace.com/shop"><Icon size={'30px'} icon={ic_shopping_cart} /></a></NavigationItem>
                    {/* <NavigationItem className="NavigationItem">PLAY</NavigationItem> */}
                    <NavigationItem className="NavigationItem"><a target="_blank" href="https://www.instagram.com/strolls.app"><Icon size={'30px'} icon={instagram} /></a></NavigationItem>
                    <NavigationItem className="NavigationItem"><a target="_blank" href="https://www.patreon.com/AmirBech"><div className="Patreon" /></a></NavigationItem>
                </ul>
                <div className={`Modal ${this.state.isInfoOpen ? "ModalOpen" : ""}`}>
                    <Info />
                </div>
            </>

        )
    }
}

export default NavigationItems;