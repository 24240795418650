import React from 'react';
import '../../../../containers/App.css';
import { Icon } from 'react-icons-kit';
import { instagram } from 'react-icons-kit/fa/instagram';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import profilePicture from '../../../../assets/ProfilePictures/AmirBECH.jpg';
import trumanBrewery from '../../../../assets/ExhibitionPhotos/AmirBECH_RubikSpaceShow_OldTrumanBrewery_2018.jpg';
import signatureArtPrize from '../../../../assets/ExhibitionPhotos/SignatureArtPrize2020.jpeg';
import { sphere } from 'react-icons-kit/icomoon/sphere';
import strollsThumbnail from '../../../../assets/ProfilePictures/Strolls.png';

const Info = (props) => {
    return (
        <>
            <div className="Info">
                <div className="Artist">
                    <div className="ProfilePicture">
                        <img src={profilePicture} alt="AmirBech" className="ProfilePicture" />
                    </div>
                    <div className="AboutMe">
                        <p>Hello! Thanks for visiting.</p>
                        <p>My name is AmirBech. A lifelong student in visual art and very much interested in data visualisation and what I would call ‘datagraphy’.</p>
                        <p>Born in Tehran and made in London, all my life I lived in big cities. City is my nature and I love the energy, sense of movement and liveliness of big cities. My work is about your seeing, although it may seem it is a product of my seeing.</p>
                        <p>Say hi when you pass by and always have a good journey.</p>
                        <div className="PersonalLinks">
                            <div className="Insta">
                                <a target="_blank" href="https://www.instagram.com/amirbech"><Icon size={'50px'} icon={instagram} /></a>
                            </div>
                            <div className="LinkedIn">
                                <a target="_blank" href="https://www.linkedin.com/in/amirbech/"><Icon size={'50px'} icon={linkedin} /></a>
                            </div>
                            <div className="Portfolio">
                                <a target="_blank" href="https://www.rubikspace.com/amir-bech"><Icon size={'50px'} icon={sphere} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Project">
                    <div className="AboutProject">
                        <p>The current presentation is a short demo, a representation of London, Bus 11.</p>
                        <p>Scrolling photographs are made of stitched panoramas resulting in miles and miles of photographs. The effect was achieved by layering street sides on each other in an attempt to make a virtual graffiti effect.</p>
                        <p>It’s fascinating how we remember cities and how it remembers us. In this project, I try to imagine how the city, streets, buildings and places record memory.</p>
                        <p>Keep up to date as this is an ongoing project. Follow 'Strolls.app' Instagram page.</p>
    <p>You can support the project by becoming my patron on Patreon. Find out more by navigating to my <a target="_blank" href="https://www.patreon.com/amirbech">Patreon page.</a> </p>
    <h2>Exhibition at The Old Truman Brewery</h2><h5>Summer.2018</h5>
    
                        <div className="TrumanBrewery">
                            <img src={trumanBrewery} alt="GlitchlandX2" className="TrumanBrewery" />
                        </div>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/2zfxjbgTYYM?rel=0&modestbranding=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h2>Degree Art - Signature Art Prize</h2><h5>Winter.2020</h5>
                        <div className="SignatureArtPrize">
                            <img src={signatureArtPrize} alt="Glitchland" className="SignatureArtPrize" />
                        </div>
                        <div className="Strolls">
                            <img src={strollsThumbnail} alt="StrollsThumbnail" className="Strolls" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Info;
