import React, { useState, useEffect } from "react";
import ImageHolder from '../ImageHolder/ImageHolder';
import _ from "lodash";
import photoData from '../../assets/london/data/photoData';
// import ReactSlider from 'react-slider';
// import useLoading from '../../assets/Buttons/useLoading';
// import Loading from "../../assets/Buttons/Loading";


function PhotoCarousel() {
  const [speed, setSpeed] = useState(600)
  // const [loading, showLoading, hideLoading]=useLoading()
  // const [loading, setloading] = useState(true)
  // useEffect(() => {
  //   setTimeout(() => {
  //     setloading(false)
  //   }, 3000)

  // }, [])

  return (
    <div>
      <div className="screen" style={{ animation: `moveSlideshow ${speed}s linear infinite` }}>
        {/* {!loading ? */}
          <div className="photowrapper">
            {photoData.map(img => (
              <ImageHolder image={_.get(img, "image", null)} name={_.get(img, "name", null)} />
            ))}
          </div>
          :
          {/* // <Loading />} */}
        <div
          className="layer2"
        >
          {_.reverse(photoData).map(img => (
            <ImageHolder image={img.image} effect="layer2Effect" />

          ))}
        </div>
      </div>
      {/* <div className="speedometer" >
        <ReactSlider
          min={-600}
          max={-1}
          onChange={(value) => setSpeed(-value)}
          renderThumb={(props, state) => <div {...props}></div>}
          thumbClassName="example-thumb"
          defaultValue={-600}
          defaultValue={[0, 100]} withBars
          invert
          step={4}
        />
      </div> */}
    </div>
  );
}
export default PhotoCarousel;
