import React from 'react';
import '../../containers/App.css';

const NavigationItem = (props) => (
    <ul className="List">
         <li className="NavigationItem">
             <a 
                 herf={props.link}>{props.children}
             </a>
        </li>
    </ul>
);

export default NavigationItem