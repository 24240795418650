import React from 'react';

import strollsLogo from '../../../assets/Logo/Strolls.app-WhiteLogo2020.png';
import "../../../containers/App.css";

const logo = (props) => (
    <div className="Logo">
        <img src={strollsLogo} alt="Strolls" className="img" />
    </div>
)

export default logo;