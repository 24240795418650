import React, { useRef, useEffect } from "react";
// import { EXIF } from "exif-js";
import exifr from "exifr";

function ImageHolder({ image, name, effect }) {
  const refImage = useRef();
  useEffect(() => {
    (async () => {
      let { latitude, longitude } = await exifr.gps(image);
      // console.log("image", image);
      // console.log("latitude", latitude);
      // console.log("longitude", longitude);
    })();
    (async () => {
      const meta = await exifr.parse(image);
      // console.log("image", image);
      // console.log("meta", meta);
    })();
  }, [image]);
  return (
    <div className="imagewrapper">
      <img ref={refImage} src={image} className={`image ${effect && effect}`} id="img1" />
      {/* <div className="name" onClick={() => alert(name)}>
        {name && <h1>{name}</h1>} */}
      {/* </div> */}
    </div>
  );
}

export default ImageHolder;
