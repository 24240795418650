import React, { Component } from 'react';
import Layout from '../components/Layout/Layout';
import Banner from './Banner/Banner';

class App extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Banner />
        </Layout>
      </div>
    )
  }
}

export default App;